<script setup
        lang="ts">

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M16.7 5C16.9267 5 17.1133 5.08 17.26 5.24C17.42 5.38667 17.5 5.57333 17.5 5.8V7.94C17.5 8.38 17.32 8.6 16.96 8.6H16.46C16.1 8.6 15.92 8.38 15.92 7.94V6.58H12.9V17.42H13.42C13.86 17.42 14.08 17.6 14.08 17.96V18.46C14.08 18.82 13.86 19 13.42 19H10.58C10.1533 19 9.94 18.82 9.94 18.46V17.96C9.94 17.6 10.1533 17.42 10.58 17.42H11.1V6.58H8.08V7.94C8.08 8.38 7.9 8.6 7.54 8.6H7.04C6.68 8.6 6.5 8.38 6.5 7.94V5.8C6.5 5.57333 6.57333 5.38667 6.72 5.24C6.86667 5.08 7.05333 5 7.28 5H16.7Z" fill="currentColor"/>
</svg>
</template>

<style scoped>

</style>