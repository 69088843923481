<script setup
        lang="ts">

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <mask id="mask0_319_227" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="currentColor"/>
    </mask>
    <g mask="url(#mask0_319_227)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19 15C20.6569 15 22 13.6569 22 12C22 10.3431 20.6569 9 19 9C17.6938 9 16.5825 9.83481 16.1707 11L2 11V13L16.1707 13C16.5825 14.1652 17.6938 15 19 15Z" fill="currentColor"/>
    </g>
  </svg>
</template>

<style scoped>

</style>