<template>

  <div ref="container"
       class="absolute top-0 pt-20 left-0 w-12 sm:w-20 h-full bg-black-900 pb-4 flex flex-col items-center text-white z-30 overflow-x-hidden overflow-y-auto thin-scrollbar">
     <div class="flex flex-col w-full  justify-center">
     <button v-if="checkShow('design')"
             class="w-full flex flex-col gap-2 py-3 px-2 items-center hover:bg-black-800 text-white"
             :class="selectedTool === 'design' ? 'bg-black-800' : ''"
             id="editor.design" @click="showDesign">
        <SvgDesign class="w-6 h-6"/>
       <span class="hidable_text hidden sm:inline-block text-xxs">{{ $t('design') }}</span>
     </button>

     <button v-if="checkShow('background')"
             class="w-full flex flex-col gap-2 py-3 px-2 items-center hover:bg-black-800"
             :class="selectedTool === 'background-group' || selectedTool === 'background-video' ? 'bg-black-800' : ''"
             id="editor.background" @click="updateBackground">
        <SvgBackground class="w-6 h-6"/>
       <span class="hidable_text hidden sm:inline-block text-xxs">{{ $t('background') }}</span>
     </button>
     <button v-if="checkShow('image')"
             class="flex flex-col gap-2 py-3 px-2 items-center  hover:bg-black-800"
             :class="selectedTool === 'image' ? 'bg-black-800' : ''"
             id="editor.image" @click="uploadImage">
       <SvgImage class="w-6 h-6"/>
       <span class="hidable_text hidden sm:inline-block text-xxs">{{ $t('image') }}</span>
     </button>
      <button v-if="checkShow('text')"
              class="flex flex-col gap-2 py-3 px-2 items-center hover:bg-black-800"
              :class="selectedTool === 'text' ? 'bg-black-800' : ''"
              id="editor.text" @click="insertText">
        <SvgText class="w-6 h-6"/>
        <span class="hidable_text hidden sm:inline-block text-xxs">{{ $t('text') }}</span>
      </button>
       <button v-if="checkShow('shape')"
               class="flex flex-col gap-2 py-3 px-2 items-center hover:bg-black-800"
               :class="selectedTool === 'shape' || selectedTool === 'shape-edit' ? 'bg-black-800' : ''"
               id="editor.shape" @click="insertShape">
        <SvgShapes class="w-6 h-6"/>
        <span class="hidable_text hidden sm:inline-block text-xxs">{{ $t('shapes') }}</span>
      </button>
      <button v-if="checkShow('waveform')"
              class="flex flex-col gap-2 py-3 px-2 items-center hover:bg-black-800"
              :class="selectedTool === 'waveform' || selectedTool === 'waveform-edit' ? 'bg-black-800' : ''"
              id="editor.waveform" @click="insertWaveform">
        <SvgWaveform class="w-6 h-6"/>
        <span class="hidable_text hidden sm:inline-block text-xxs">{{ $t('waveform') }}</span>
      </button>
      <button v-if="checkShow('progress')"
              class="flex flex-col gap-2 py-3 px-2 items-center hover:bg-black-800"
              :class="selectedTool === 'progress' || selectedTool === 'progress-edit' ? 'bg-black-800' : ''"
              id="editor.progress" @click="insertProgress">
        <SvgProgress class="w-6 h-6"/>
        <span class="hidable_text hidden sm:inline-block text-xxs">{{ $t('progress') }}</span>
      </button>
      <button v-if="checkShow('subtitles')"
              class="flex flex-col gap-2 py-3 px-2 items-center hover:bg-black-800"
              :class="selectedTool === 'subtitles' ? 'bg-black-800' : ''"
              id="editor.subtitles" @click="insertSubtitles">
        <SvgCaptions class="w-6 h-6"/>
        <span class="hidable_text hidden sm:inline-block text-xxs">{{ $t('subtitles') }}</span>
      </button>
      <button v-if="segment && checkShow('transcript')"
             class="w-full flex flex-col gap-2 py-3 px-2 items-center hover:bg-black-800 text-white"
             :class="selectedTool === 'transcript' ? 'bg-black-800' : ''"
             id="editor.settings" @click="showTranscription">
        <SvgTranscript class="w-6 h-6"/>
       <span class="hidable_text hidden sm:inline-block text-xxs">{{ $t('transcript') }}</span>
      </button>
      <button v-if="segment && checkShow('settings')"
             class="w-full flex flex-col gap-2 py-3 px-2 items-center hover:bg-black-800 text-white"
             :class="selectedTool === 'settings' ? 'bg-black-800' : ''"
             id="editor.settings" @click="showSettings">
        <SvgSpeaker class="w-6 h-6"/>
       <span class="hidable_text hidden sm:inline-block text-xxs">{{ $t('audio') }}</span>
      </button>
      <button v-if="! segment && checkShow('audio')"
             class="w-full flex flex-col gap-2 py-3 px-2 items-center hover:bg-black-800 text-white"
             :class="selectedTool === 'audio' ? 'bg-black-800' : ''"
             id="editor.audio" @click="selectClip">
        <SvgSpeaker class="w-6 h-6"/>
       <span class="hidable_text hidden sm:inline-block text-xxs">{{ $t('audio') }}</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import {useUtilsStore} from "~/store/utils";
import {useAudioSegmentStore} from "~/store/audio-segment";
import {storeToRefs} from "pinia";
const route = useRoute();

const { $event, $listen, $unlisten } = useNuxtApp();

const toolMap = {
  'background': ['normal', 'title'],
  'image': ['normal', 'title'],
  'text': ['normal', 'title'],
  'shape': ['normal', 'title'],
  'settings': ['normal', 'title'],
  'design': ['normal', 'title'],
  'waveform': ['normal'],
  'progress': ['normal'],
  'subtitles': ['normal'],
  'transcript': ['normal'],
  'audio': ['normal'],
};

const mode = ref(route.query.mode || 'normal');

const utilsStore = useUtilsStore();
const segmentStore = useAudioSegmentStore();

const {imageUploadDialog, audioClipSelectDialog} = storeToRefs(utilsStore);
const {segment} = storeToRefs(segmentStore);
const selectedTool = ref(null);
const container = ref(null);

const checkContainerHeight = () => {

  if (container.value && window.innerHeight < 750) {
    container.value.classList.add('is_short');
  }
  else {
    container.value.classList.remove('is_short');
  }
}

onMounted(async () => {
  await nextTick()
  checkContainerHeight();
  window.addEventListener('resize', checkContainerHeight);
});

$listen('tool_added', (tool) => {
  selectedTool.value = tool;
});

$listen('tool_selected', (payload) => {
  selectedTool.value = payload.selectedTool;

});
const checkShow = (tool) => {
  return toolMap[tool].includes(mode.value);
}

const uploadImage = () => {
  imageUploadDialog.value = true;
}

const selectClip = () => {
  audioClipSelectDialog.value = true;
}

const insertText = () => {
  $event('tool_added', 'text');
}

const insertShape = () => {
  $event('tool_added', 'shape');
}

const insertProgress = () => {
  $event('tool_added', 'progress');
}

const insertWaveform = () => {
  $event('tool_added', 'waveform');
}

const insertSubtitles = () => {
  $event('tool_added', 'subtitles');
}

const updateBackground = () => {
  $event('tool_added', 'background-group')
}

const showSettings = () => {
  $event('tool_added', 'settings')
}

const showDesign = () => {
  $event('tool_added', 'design')
}

const showTranscription = () => {
  $event('tool_added', 'transcript')
}

onBeforeUnmount(() => {
  $unlisten('tool_added');
  $unlisten('tool_selected');
  window.removeEventListener('resize', checkContainerHeight);
});

</script>

<style scoped>
  .is_short .hidable_text {
    display: none;
  }
</style>