<template>
  <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
    <PageHead :title="title" :head="head" />
    <Body>
      <div class="flex h-screen overflow-hidden font-inter">
        <slot name="sidebar">
          <EditorSidebar/>
        </slot>
        <div class="relative flex flex-col flex-1 overflow-x-hidden min-h-max"
              :class="{'overflow-y-hidden': !canScroll, 'overflow-y-auto': canScroll}">
            <slot name="header">
            </slot>
          <slot></slot>
        </div>
      </div>
    </Body>
</Html>
</template>

<script setup>
import {useUtilsStore} from "~/store/utils";
import {storeToRefs} from "pinia";
const utilsStore = useUtilsStore();
const {imageUploadDialog} = storeToRefs(utilsStore);

const canScroll = ref(true);

const route = useRoute()
  const { t } = useI18n();

  const head = useLocaleHead({
    addDirAttribute: true,
    identifierAttribute: 'id',
    addSeoAttributes: true
  })
  const title = computed(() => t('layouts.title', { title: t(route.meta.title ?? 'TBD') }))

  watch(imageUploadDialog.value, (value) => {
    if (value) {
      canScroll.value = false;
    } else {
      canScroll.value = true;
    }
  });
</script>

<style scoped>

</style>